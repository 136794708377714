import React from "react";
import Layout from "../components/Layout/Layout";
import Styled from "@emotion/styled";
import get from "lodash/get";
import { theme } from "../utils/global-styles/theme";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";

const { breakpoints } = theme;

const cookies = ({ 
    data,
    pageContext: { locale, marketingModal },
    location: { pathname },
}) => {
    const page = get(data, "cookiePolicy.nodes[0].contentModules[0]", {}),
    Wrapper = Styled.div`
    margin: 5em 15em;
        h1 {
            font-size: 32px;
            line-height: 2em;

            @media only screen and (min-width: ${breakpoints.mobile}) {
                letter-spacing: 0;
            }
        }
        h3 {
            font-size: 24px;
            line-height: 1.5em;
            margin: 16px 0;
        }
        ul {
            padding-left: 14px;
            li {
                list-style: outside;
            }
        }
        p {
          font-size: 20px;
          line-height: 31px;
            font-family: ${theme.fontFaces.agStandardBold};
            b {
                font-weight: 800;
            }
            i {
                font-style: italic;
            }
        }
        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
          margin: 24px 5%;
        }
    `,
    richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: node => {
          return node && node.props ? <b>{node}</b> : <b>{node}</b>;
        },
        [MARKS.ITALIC]: node => {
          return node && node.props ? <i>{node.props}</i> : <i>{node}</i>;
        },
        [MARKS.UNDERLINE]: node => {
          return node && node.props ? (
            <u>{node.props.children}</u>
          ) : (
            <u>{node}</u>
          );
        },
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <a className={"primary"} href={node.data.uri}>
            {children}
          </a>
        ),
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3 className="primary">{children}</h3>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      },
    },
    richTextNodes = get(page, "richText.json.content", []);
  return (
    <Layout currentPath={pathname} locale={locale} marketingModal={marketingModal}>
      <Wrapper>
        {richTextNodes.map((item, index) => {
          return documentToReactComponents(item, richTextOptions);
        })}
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
query ($locale: String! ){
    cookiePolicy: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "cookie" }
        node_locale: { eq: $locale} }
    ) {
      nodes {
        contentModules {
          ... on ContentfulRichTextModule {
            richText {
              json
            }
          }
        }
      }
    }
  }
`;

export default cookies;
